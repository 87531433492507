import { Link } from "gatsby";
import React from "react";

type Props = {
  title: string;
  url: string;
  active: boolean;
  isSearchItem?: boolean;
};

const BreadCrumb = ({ title, url, active, isSearchItem = false }: Props) => {
  return (
    <div className="flex flex-row">
      <div>
        {active ? (
          <span className="breadcrumb dark:dark-breadcrumb hover:text-blue-70">
            {title}
          </span>
        ) : isSearchItem ? (
          <div className="text-gray-400 hover:text-blue-700">{title}</div>
        ) : (
          <Link
            to={url}
            className="text-text-gray-8 dark:text-blue-600 hover:text-blue-700"
          >
            {title}
          </Link>
        )}
      </div>
      {!active && (
        <div>
          <span className="text-gray-500 mx-2">{">"}</span>
        </div>
      )}
    </div>
  );
};

export default BreadCrumb;
