import { Link, navigate } from "gatsby";
import React, { useContext, useEffect, useState } from "react";
import { ResponsiveContext } from "../../contexts/ResponsiveContext";
import { useCurrentPath } from "../../contexts/useCurrentPath";
import { useSiteMetadataVersions } from "../../queries/useSiteMetadataVersions";
import { DocOverview } from "../../templates/appPage";
import { getUrlForChangedVersion } from "./sidebar.utils";
import documentationLogo from "../../images/domino-logo-large.png";
import { MainPath } from "../../types/types";
import { buildAppUrl } from "../../utils/buildAppUrl";
import getDisplayVersion from "../../utils/getDisplayVersion";
import { useLocation } from "../../contexts/useLocation";
import { Layout, Menu, Select } from "antd";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import { RightOutlined } from "@ant-design/icons";
import { WindowLocation } from "@reach/router";
import { compareVersions } from "../../utils/getIntegralVersion";
import { useVersionLookup } from "../../utils/getVersionLookup";

const { Sider } = Layout;

const backgroundColorActive = `#111A20`;
const backgroundColorInActive = `#111A20`;
type Props = {
  docs: DocOverview[];
  version: string;
  categoryVersions: string[];
  permalink: string;
  title: string;
};

const getLinkWithPath = (
  doc: DocOverview,
  url: string,
  isLocationPathAndUrlEqual: boolean,
) => (
  <Link
    key={doc.id}
    to={url}
    style={{
      width: "100%",
      display: "block",
    }}
    id={`${isLocationPathAndUrlEqual && "active-menu-item"}`}
    {...{
      version: doc.version,
    }}
  >
    <span>{doc.sidebar ? doc.sidebar : doc.title}</span>
  </Link>
);

type SubMenuItemProps = {
  doc: DocOverview;
  currentPath: MainPath;
  version: string;
  latestVersion: string;
  location: WindowLocation;
};

const getSubMenuItems = (props: SubMenuItemProps): ItemType => {
  const { doc, latestVersion, version, currentPath, location } = props;
  const url = buildAppUrl({
    latestVersion,
    permalink: doc.permalink,
    slug: doc.slug,
    title: doc.title,
    version,
    path: currentPath,
  });
  const isLocationPathAndUrlEqual = location.pathname === url;
  if (doc.children.length === 0) {
    return {
      style: isLocationPathAndUrlEqual
        ? { backgroundColor: backgroundColorActive }
        : { backgroundColor: backgroundColorInActive },
      key: doc.id,
      label: getLinkWithPath(doc, url, isLocationPathAndUrlEqual),
    };
  } else {
    return {
      style: isLocationPathAndUrlEqual
        ? { backgroundColor: backgroundColorActive }
        : { backgroundColor: backgroundColorInActive },
      key: doc.id,
      label: getLinkWithPath(doc, url, isLocationPathAndUrlEqual),
      children: getMenuItemChildren({
        doc,
        currentPath,
        version,
        latestVersion,
        location,
      }),
    };
  }
};

const getMenuItemChildren = (props: SubMenuItemProps): ItemType[] => {
  const { doc: docs, latestVersion, version, currentPath, location } = props;
  return docs.children.map((doc) =>
    getSubMenuItems({ doc, currentPath, version, latestVersion, location }),
  );
};

type MenuItemProps = {
  docs: DocOverview[];
  currentPath: MainPath;
  version: string;
  latestVersion: string;
  location: WindowLocation;
};

const getMenuItems = (props: MenuItemProps): ItemType[] => {
  const { docs, latestVersion, version, currentPath, location } = props;
  return docs.map((doc) => {
    const item = getSubMenuItems({
      doc,
      currentPath,
      version,
      latestVersion,
      location,
    });
    // If this menu item is marked as a separator then add a top border.
    if (doc["separator"] == "true" && item !== null) {
      item["className"] = "border-t border-primary-lighter";
    }
    return item;
  });
};

const Sidebar = (props: Props) => {
  const { categoryVersions, version, docs, permalink, title } = props;
  const { dispatch } = useContext(ResponsiveContext);
  const [openKeys, setOpenKeys] = useState<string[]>([]);
  const [docIdsKeyValuePair, setDocIdsKeyValuePair] = useState<any>();
  const [selectedKey, setSelectedKey] = useState<string>();
  const [collapsed, setCollapsed] = useState<boolean>(false);

  const currentPath = useCurrentPath();
  const { latestVersion } = useSiteMetadataVersions();
  const location = useLocation();
  const versionLookup = useVersionLookup();

  const changeSelectedVersion = (version: string) => {
    if (currentPath == null) return;
    if (currentPath === "release_notes") return;
    const url = getUrlForChangedVersion({
      latestVersion: latestVersion,
      version: version,
      title: title,
      currentPath: currentPath,
      permalink: permalink,
      versionLookup: versionLookup,
    });
    navigate(url);
  };

  let docIds: any = {};
  const subMenuIds = (subDoc: DocOverview) => {
    subDoc.children.map((doc) => {
      docIds = { ...docIds, [doc.id]: `${docIds[subDoc.id]}/${doc.id}` };
      if (!(doc.children.length === 0)) {
        subMenuIds(doc);
      }
    });
  };

  const fetchIdsOfDocs = () => {
    docs.map((doc) => {
      docIds = { ...docIds, [doc.id]: doc.id };
      if (!(doc.children.length === 0)) {
        subMenuIds(doc);
      }
    });
    setDocIdsKeyValuePair(docIds);
  };

  const checkSubMenuItems = (doc: DocOverview) => {
    const url = buildAppUrl({
      latestVersion,
      permalink: doc.permalink,
      slug: doc.slug,
      title: doc.title,
      version,
      path: currentPath as MainPath,
    });
    const isLocationPathAndUrlEqual = location.pathname === url;
    if (isLocationPathAndUrlEqual) {
      setSelectedKey(doc.id);
    }
    if (!(doc.children.length === 0)) {
      checkMenuItems(doc);
    }
  };

  const checkMenuItems = (subDocs: DocOverview) => {
    subDocs.children.map((doc) => checkSubMenuItems(doc));
  };

  const fetchSelectedKeyByPath = () => {
    docs.map((doc) => checkSubMenuItems(doc));
  };

  const loadCallback = () => {
    // Time interval to load menu completely
    // Checking for active menu item to clear interval.
    const menuLoadInterval = setInterval(() => {
      const isActiveElementExist = document.getElementById("active-menu-item");
      if (isActiveElementExist) {
        setTimeout(() => {
          document
            .getElementById("active-menu-item")
            ?.scrollIntoView({ behavior: "smooth", block: "start" });
          clearInterval(menuLoadInterval);
        }, 500);
      }
    }, 500);
  };

  useEffect(() => {
    fetchIdsOfDocs();
    fetchSelectedKeyByPath();
    loadCallback();
  }, []);

  useEffect(() => {
    fetchIdsOfDocs();
    fetchSelectedKeyByPath();
  }, [version, title]);

  useEffect(() => {
    if (selectedKey && docIdsKeyValuePair) {
      setOpenKeys(
        docIdsKeyValuePair[selectedKey] &&
          docIdsKeyValuePair[selectedKey].split("/"),
      );
    }
  }, [docIdsKeyValuePair, selectedKey]);

  const onOpenChange = (keys: string[]) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    const rootSubmenuKeys = docs.map((doc) => doc.id);
    // @ts-ignore
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const onMenuClick = (keys: string[]) => {
    setOpenKeys(keys);
  };

  const items = getMenuItems({
    docs,
    // @ts-ignore
    currentPath,
    version,
    latestVersion,
    location,
  });

  function versionCompare(a: any, b: any) {
    if (a === null || b === null) return 0;
    a = a.label.props;
    b = b.label.props;
    if (a.to == "/release_notes/preview-features/") return -1;
    if (b.to == "/release_notes/preview-features/") return +1;
    return compareVersions(b.version, a.version);
  }

  const sorted =
    currentPath == "release_notes" ? items.sort(versionCompare) : items;

  return (
    <Sider
      className={`ant-sider ${
        collapsed ? "ant-layout-sider-collapsed" : "ant-sider-collapse-menu"
      }`}
      style={{
        left: 0,
        top: 0,
        bottom: 0,
        position: "fixed",
      }}
      breakpoint="lg"
      collapsedWidth={0}
      onBreakpoint={(broken) => {
        console.log(broken);
      }}
      onCollapse={(collapsed) => setCollapsed(collapsed)}
      width={350}
      collapsed={collapsed}
    >
      <div className={`domino-logo ${collapsed && "collapsed-logo"}`}>
        <Link to="/">
          <img
            loading="lazy"
            src={documentationLogo}
            className="!my-0 !mx-0 lg:!mx-0 w-full cursor-pointer nav-logo"
            alt="domino logo"
            id="domino-logo"
          />
        </Link>
      </div>
      <div className="sidebar-leftnav" id="leftnav">
        <div className="version-selection">
          {currentPath && currentPath !== "release_notes" && (
            <Select
              value={version}
              getPopupContainer={(trigger) => trigger.parentElement}
              onChange={(event) => {
                changeSelectedVersion(event);
                dispatch({ type: "closeSidebar" });
              }}
            >
              {categoryVersions.map((v) => {
                return (
                  <Select.Option key={v} value={v}>
                    {getDisplayVersion(v)}
                  </Select.Option>
                );
              })}
            </Select>
          )}
        </div>
        <Menu
          theme="dark"
          mode="inline"
          expandIcon={<RightOutlined />}
          style={{
            borderRight: 0,
          }}
          items={sorted}
          openKeys={openKeys}
          defaultOpenKeys={openKeys}
          onOpenChange={onOpenChange}
          onClick={({ keyPath }) => onMenuClick(keyPath)}
        />
      </div>
    </Sider>
  );
};

export default Sidebar;
