import React, { useContext, useEffect } from "react";
import { Switch } from "antd";
import { ThemeContext } from "../../contexts/ThemeContext";

const ToggleNightMode = () => {
  const { dark, toggleDark } = useContext(ThemeContext);

  useEffect(() => {
    if (dark) {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
  }, []);

  return (
    <div className="toggle-theme-container">
      <div className="lg:flex switch-styles">
        <label className="navbar-text text-sm mr-2 switch-label">Light</label>
        <div className="justify-center items-center inline-block">
          <div id="flexSwitchCheckDefault">
            <Switch checked={dark} onChange={toggleDark} />
          </div>
        </div>
        <label className="navbar-text text-sm mr-2 switch-label">Dark</label>
      </div>
    </div>
  );
};

export default ToggleNightMode;
