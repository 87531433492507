import { buildAppUrl } from "../utils/buildAppUrl";
import BreadCrumb from "./BreadCrumb";
import React from "react";
import { DocOverview } from "../templates/appPage";

type Props = {
  docTrace: DocOverview[];
  latestVersion: string;
  id: string | undefined;
};

const BreadCrumbList = ({ docTrace, latestVersion, id }: Props) => {
  return (
    <nav className="bg-grey-light rounded-md w-full">
      <div className="list-reset flex flex-wrap">
        <div className="flex flex-row">
          <div>
            <img src="/images/site_icons/home.svg" className="home"></img>
          </div>
          <div>
            <span className="text-gray-500 mx-2">&gt;</span>
          </div>
        </div>
        {docTrace.map((d) => {
          const url = buildAppUrl({
            latestVersion,
            permalink: d.permalink,
            slug: d.slug,
            title: d.title,
            version: d.version,
            path: d.category,
          });
          return (
            <BreadCrumb
              key={d.id}
              active={d.id === id}
              title={d.title}
              url={url}
            />
          );
        })}
      </div>
    </nav>
  );
};
export default BreadCrumbList;
