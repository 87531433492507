import React, { createContext, useContext, useEffect, useState } from "react";
import { MainPath } from "../types/types";
import { SearchBoxContext } from "./SearchBoxContext";

const initialState: MainPath | null = null;

const CurrentPathContext = createContext<MainPath | null>(initialState);

export function CurrentPathProvider({
  currentPath,
  children,
}: {
  currentPath: MainPath | null;
  children?: React.ReactNode;
}) {
  const [value, setValue] = useState(currentPath);
  const { setSelectedVersion, setSelectedGuide } = useContext(SearchBoxContext);
  useEffect(() => {
    if (currentPath) {
      setValue(currentPath);
      setSelectedVersion(undefined);
      setSelectedGuide(undefined);
    }
  }, [currentPath]);

  return (
    <CurrentPathContext.Provider value={value}>
      {children}
    </CurrentPathContext.Provider>
  );
}

export function useCurrentPath(): MainPath | null {
  return useContext(CurrentPathContext);
}
