import { buildSearchBox } from "@coveo/headless";
import React, { useContext, useEffect, useState } from "react";
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { SearchBoxContext } from "../contexts/SearchBoxContext";
import { useLocation } from "../contexts/useLocation";
import { headlessEngine } from "../coveo/Engine";
import { navigate } from "gatsby";
import { ResponsiveContext } from "../contexts/ResponsiveContext";

type Props = {
  hideResults?: boolean;
  isSearchScreen?: boolean;
};

const coveoSearchBox = buildSearchBox(headlessEngine);
const SearchBox = ({ isSearchScreen = false }: Props) => {
  const { setShowSearchResult, setSearchExecuted } =
    useContext(SearchBoxContext);
  const location = useLocation();
  const [controllerState, setControllerState] = useState(coveoSearchBox.state);
  const [, setSearchCompleted] = useState(false);
  const { responsiveState, dispatch } = useContext(ResponsiveContext);

  const submitSearch = () => {
    if (responsiveState.isMenuActive) {
      dispatch({ type: "toggleMenu" });
    }
    setSearchExecuted(true);
    if (!isSearchScreen) {
      navigate("/search");
    } else {
      setSearchCompleted(true);
      setShowSearchResult(true);
      coveoSearchBox.submit();
    }
  };

  useEffect(() => {
    if (!controllerState.isLoading) {
      setSearchExecuted(false);
    }
  }, [controllerState.isLoading]);

  useEffect(() => {
    if (location.pathname.includes("/search")) {
      submitSearch();
    }
    return coveoSearchBox.subscribe(() =>
      setControllerState(coveoSearchBox.state)
    );
  }, []);

  return (
      <div className={`search-box`}>
        <form
          className="w-full flex flex-row items-center"
          onSubmit={(event) => {
            event.preventDefault();
            submitSearch();
          }}
        >
          <Input
            id="searchInput"
            prefix={<SearchOutlined onClick={() => submitSearch()} />}
            value={controllerState.value}
            placeholder="Search"
            onChange={(e) => coveoSearchBox.updateText(e.target.value)}
            className="w-full focus-visible:outline-none bg-white dark:bg-transparent text-black search-input"
          />
        </form>
      </div>
  );
};

export default SearchBox;
